<template>
  <div class="faceoff">
    <div class="container" v-if="challenge" @click="viewChallenge(challenge.id)">

      <div class="row" style="padding:0 12px 0px 10px;">
        <div class="col-6" style="padding:0;">
          <p v-if="challenge.live" style="margin-top:0; margin-bottom:5px;"><span style="color:#ED4245;">Live</span></p>
          <p v-if="challenge.results" style="margin-top:0; margin-bottom:5px;"><span
              style="padding: 2px 6px 2px 6px;  border-radius: 5px; color:gray;">Final</span></p>
        </div>
        <div class="col-6" style="padding:0;">
          <p v-if="challenge.freePlay" style="margin-bottom:5px; margin-top: 0; color:gray; text-align:right;"><span
              style="padding: 2px 6px 2px 6px; background-color: #383b3d; border-radius: 5px; color:#57F287;"><i>Free
                Play</i></span></p>
        </div>
      </div>

      <div class="row" style="padding:0px 10px 0 10px;">
        <div class="col-3">
          <img class="logo" v-bind:src="'/server/logos/' + challenge.away.logo" height="45" />
          <!-- <span>{{challenge.away.abbrev}}</span> -->
        </div>

        <!-- SCORE -->
        <div class="col-3" v-if="challenge.results">
          <!-- <div :class="{winner: (challenge.results && challenge.results.winner.id === challenge.away.id)}">
            <div class="odds">
              <p class="text">{{challenge.results.awayPoints}}</p>
            </div>
          </div> -->
          <div class="odd-container-results">
            <div class="odds" v-if="challenge.away.id === challenge.line.id">
              <span :class="{ winner: (challenge.results && challenge.results.winner.id === challenge.away.id) }">{{
                challenge.results.awayPoints }}</span>
              <p class="money-line"><span v-if="challenge.lineFOdds > 0">+</span>{{ challenge.lineFOdds }}</p>
            </div>
            <div class="odds" v-else>
              <span :class="{ winner: (challenge.results && challenge.results.winner.id === challenge.away.id) }">{{
                challenge.results.awayPoints }}</span>
              <p class="money-line"><span v-if="challenge.lineAOdds > 0">+</span>{{ challenge.lineAOdds }}</p>
            </div>
          </div>
        </div>
        <!-- SPREAD -->
        <div class="col-3" v-if="challenge.line !== null">
          <div class="odd-container"
            :class="{ hit: (challenge.results && challenge.results.spread.id === challenge.away.id) }">
            <div class="odds" v-if="challenge.away.id === challenge.line.id">
              -{{ challenge.spread }}
              <p class="money-line">{{ challenge.spreadFOdds }}</p>
            </div>
            <div class="odds" v-else>
              +{{ challenge.spread }}
              <p class="money-line">{{ challenge.spreadAOdds }}</p>
            </div>
          </div>
        </div>
        <!-- TOTAL -->
        <div class="col-3">
          <div class="odd-container" :class="{ hit: (challenge.results && challenge.results.totalpoints === 'OV') }">
            <div class="odds">
              o{{ challenge.totalpoints }}
              <p class="money-line">{{ challenge.totalFOdds }}</p>
            </div>
          </div>
        </div>
        <!-- MONEY LINE -->
        <div class="col-3" v-if="!challenge.results && challenge.line">
          <div class="odd-container"
            :class="{ hit: (challenge.results && challenge.results.winner.id === challenge.away.id) }">
            <div class="odds" v-if="challenge.away.id === challenge.line.id">
              <span v-if="challenge.lineFOdds > 0">+</span>{{ challenge.lineFOdds }}
              <p class="money-line">ML</p>
            </div>
            <div class="odds" v-else>
              <span v-if="challenge.lineAOdds > 0">+</span>{{ challenge.lineAOdds }}
              <p class="money-line">ML</p>
            </div>
          </div>
        </div>
      </div>




      <!-- HOME TEAM -->

      <div class="row" style="padding:0 10px 0 10px;">
        <div class="col-3">
          <img class="logo" v-bind:src="'/server/logos/' + challenge.home.logo" height="45" />
        </div>
        <!-- SCORE -->
        <div class="col-3" v-if="challenge.results">
          <!-- <div :class="{winner: (challenge.results && challenge.results.winner.id === challenge.home.id)}">
            <div class="odds">
              <p class="text">{{challenge.results.homePoints}}</p>
            </div>
          </div> -->
          <div class="odd-container-results">
            <div class="odds" v-if="challenge.home.id === challenge.line.id">
              <span :class="{ winner: (challenge.results && challenge.results.winner.id === challenge.home.id) }">{{
                challenge.results.homePoints }}</span>
              <p class="money-line"><span v-if="challenge.lineFOdds > 0">+</span>{{ challenge.lineFOdds }}</p>
            </div>
            <div class="odds" v-else>
              <span :class="{ winner: (challenge.results && challenge.results.winner.id === challenge.home.id) }">{{
                challenge.results.homePoints }}</span>
              <p class="money-line"><span v-if="challenge.lineAOdds > 0">+</span>{{ challenge.lineAOdds }}</p>
            </div>
          </div>
        </div>
        <!-- SPREAD -->
        <div class="col-3" v-if="challenge.line !== null">
          <div class="odd-container"
            :class="{ hit: (challenge.results && challenge.results.spread.id === challenge.home.id) }">
            <div class="odds" v-if="challenge.home.id === challenge.line.id">
              -{{ challenge.spread }}
              <p class="money-line">{{ challenge.spreadFOdds }}</p>
            </div>
            <div class="odds" v-else>
              +{{ challenge.spread }}
              <p class="money-line">{{ challenge.spreadAOdds }}</p>
            </div>
          </div>
        </div>
        <!-- TOTAL -->
        <div class="col-3">
          <div class="odd-container" :class="{ hit: (challenge.results && challenge.results.totalpoints === 'UN') }">
            <div class="odds">
              u{{ challenge.totalpoints }}
              <p class="money-line">{{ challenge.totalAOdds }}</p>
            </div>
          </div>
        </div>
        <!-- MONEY LINE -->
        <div class="col-3" v-if="!challenge.results && challenge.line">
          <div class="odd-container"
            :class="{ hit: (challenge.results && challenge.results.winner.id === challenge.home.id) }">
            <div class="odds" v-if="challenge.home.id === challenge.line.id">
              <span v-if="challenge.lineFOdds > 0">+</span>{{ challenge.lineFOdds }}
              <p class="money-line">ML</p>
            </div>
            <div class="odds" v-else>
              <span v-if="challenge.lineAOdds > 0">+</span>{{ challenge.lineAOdds }}
              <p class="money-line">ML</p>
            </div>
          </div>
        </div>
      </div>


      <div class="row" style="padding:0 10px 0px 10px;">
        <div class="col-6" style="padding:0;">
          <p style="margin-top:5px; margin-bottom:0; color:gray;">{{ formattedGameTime | moment("ddd, MM/D h:mm A") }}
          </p>
        </div>
        <div class="col-6" style="padding:0;">
          <p style="margin-top:5px; margin-bottom:0; color:gray; text-align:right;">
            <i>{{ challenge.bets.length }} bets</i>
            <!-- <span v-if="challenge.theme" style="margin-left: 5px; padding: 2px 6px 2px 6px; background-color: #383b3d; border-radius: 5px; color:#ee5f60;" >{{challenge.theme}}</span> -->
            <!-- <span v-if="challenge.gameDay" style="margin-left: 5px; padding: 2px 6px 2px 6px; background-color: #383b3d; border-radius: 5px; color:#ee5f60;" >College GameDay</span> -->
            <!-- <i>{{challenge.theme}}</i> <i v-if="challenge.gameDay">Game Day</i> -->
          </p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "NewFaceoff",
  props: {
    challenge: null,
  },
  methods: {
    viewChallenge(cid) {
      this.$router.push({
        name: "Challenge",
        params: { id: cid },
      });
    },
  },
  computed: {
    formattedGameTime() {
      return `${this.challenge.eventDate} ${this.challenge.eventTime}`;
    }
  }
};
</script>

<style scoped>
.faceoff {
  border-radius: 4px;
  /* background-color: #242424; */
  padding: 10px;
  /* box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22); */
  border: 1px solid #303030;
  margin-bottom: 10px;
}

.center-text {
  text-align: center;
}

.vegas {
  margin: 5px;
}

.challenge {
  text-align: center;
}

.at {
  padding: 15px;
  font-size: large;
  font-weight: 700;
  font-size: x-large;
}

.col-3 {
  padding: 2px;
}

.odd-container {
  position: relative;
  border: 1px solid gray;
  background-color: #222222;
  border-radius: 5px;
  margin: 1px;
  height: 100%;
  width: 100%;
}

.odd-container-results {
  position: relative;
  border: 1px solid #222222;
  background-color: #222222;
  border-radius: 5px;
  margin: 1px;
  height: 100%;
  width: 100%;
}

.odd-container {
  position: relative;
  background-color: #222222;
  border-radius: 5px;
  margin: 1px;
  height: 100%;
  width: 100%;
}

.hit {
  border: 2px solid #00bc8c;
}

.text {
  position: absolute;
  top: 50%;
  left: 50%;
  /* Optional: For horizontal centering */
  transform: translate(-50%, -50%);
  /* Moves the text to the center */
  text-align: center;
  font-size: large;
  font-weight: 550;
}

.winner {
  color: #00bc8c;
}

.odds {
  vertical-align: middle;
  height: 100%;
  width: 100%;
  position: absolute;
  text-align: center;
}

.money-line {
  font-size: 10px;
  color: gray;
}

.block {
  position: relative;
  display: block;
}

.block:before,
.block:after {
  content: '';
  position: absolute;
  left: -2px;
  top: -2px;
  background-color: black;
  background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000, #fb0094,
      #0000ff, #00ff00, #ffff00, #ff0000);
  background-size: 400%;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  z-index: -1;
  animation: steam 20s linear infinite;
  border-radius: 5px;
}

@keyframes steam {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.block:after {
  filter: blur(50px);

}

.chevron {
  width: 0;
  height: 0;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-right: 3px solid black;
  /* The width of the chevron arrow */
}
</style>